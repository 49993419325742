var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm1" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "오더내역" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "정비오더No.",
                              name: "workOrderNo",
                            },
                            model: {
                              value: _vm.data.workOrderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workOrderNo", $$v)
                              },
                              expression: "data.workOrderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "정비유형",
                            },
                            model: {
                              value: _vm.data.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woWorkTypeCd", $$v)
                              },
                              expression: "data.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              label: "정비오더명",
                              name: "workPlanName",
                            },
                            model: {
                              value: _vm.data.workPlanName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workPlanName", $$v)
                              },
                              expression: "data.workPlanName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              data: _vm.data,
                              type: "user",
                              label: "작업계획자",
                              name: "planUserId",
                            },
                            model: {
                              value: _vm.data.planUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "planUserId", $$v)
                              },
                              expression: "data.planUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              label: "대표설비Tag",
                              name: "equipmentCd",
                            },
                            on: { dataChange: _vm.equipChange },
                            model: {
                              value: _vm.data.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentCd", $$v)
                              },
                              expression: "data.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.data.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "funcLocationCd", $$v)
                              },
                              expression: "data.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "작업부서",
                              name: "workDeptCd",
                            },
                            model: {
                              value: _vm.data.workDeptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workDeptCd", $$v)
                              },
                              expression: "data.workDeptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.data.reqDeptCd
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                            },
                            [
                              _c("c-field", {
                                attrs: {
                                  editable: false,
                                  type: "dept_user",
                                  data: _vm.data,
                                  deptValue: "reqDeptCd",
                                  label: "작업요청자",
                                  name: "actionCompleteCheckUserId",
                                },
                                model: {
                                  value: _vm.data.reqUserId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "reqUserId", $$v)
                                  },
                                  expression: "data.reqUserId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-work-order", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              label: "선행 정비오더",
                              name: "preWorkPlanId",
                            },
                            model: {
                              value: _vm.data.preWorkPlanId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "preWorkPlanId", $$v)
                              },
                              expression: "data.preWorkPlanId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              required: "",
                              isArray: false,
                              editable: _vm.editable && !_vm.disabled,
                              label: "정비작업팀",
                              name: "workDepts",
                            },
                            model: {
                              value: _vm.data.workDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workDepts", $$v)
                              },
                              expression: "data.workDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              range: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              type: "date",
                              label: "작업계획기간",
                              name: "planDts",
                            },
                            model: {
                              value: _vm.data.planDts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "planDts", $$v)
                              },
                              expression: "data.planDts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.apprDisabled,
                              rows: 7,
                              label: "작업요청 세부사항",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "gridCost",
                              attrs: {
                                title: "비용정산 규칙",
                                isTitle: true,
                                gridHeightAuto: true,
                                columns: _vm.gridCost.columns,
                                data: _vm.data.costList,
                                gridHeight: _vm.gridCost.height,
                                editable:
                                  _vm.editable &&
                                  !_vm.disabled &&
                                  !_vm.apprDisabled,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "costCenterCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      !_vm.apprDisabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowCost },
                                          })
                                        : _vm._e(),
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      !_vm.apprDisabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowCost,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "grid0",
                              attrs: {
                                title: "관련설비",
                                isTitle: true,
                                gridHeightAuto: true,
                                columns: _vm.grid0.columns,
                                data: _vm.grid0.data,
                                gridHeight: _vm.grid0.height,
                                editable:
                                  _vm.editable &&
                                  !_vm.disabled &&
                                  !_vm.apprDisabled,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "equipmentCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      Boolean(_vm.param.workPlanId) &&
                                      !_vm.apprDisabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowEquip },
                                          })
                                        : _vm._e(),
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      Boolean(_vm.param.workPlanId) &&
                                      !_vm.apprDisabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowEquip,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        _c("div", { staticClass: "popup-bottom-bar" }, [
          _c(
            "div",
            { staticClass: "popup-bottom-bar-btngroup" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  !_vm.param.workPlanId && _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "작업표준", icon: "upload_file" },
                        on: { btnClicked: _vm.standardData },
                      })
                    : _vm._e(),
                  !_vm.param.workPlanId && _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "이전 정비오더", icon: "upload_file" },
                        on: { btnClicked: _vm.copyData },
                      })
                    : _vm._e(),
                  _c("c-appr-btn", {
                    ref: "appr-btn",
                    attrs: {
                      name: "equipment-daily-appr-btn",
                      apprRequestTitle: "정비오더 승인요청",
                      editable: _vm.editable,
                      approvalInfo: _vm.approvalInfo,
                    },
                    on: {
                      beforeApprAction: _vm.saveDataAppr,
                      callbackApprAction: _vm.approvalCallback,
                      requestAfterAction: _vm.getDetail,
                    },
                  }),
                  _vm.editable &&
                  _vm.param.workPlanId &&
                  _vm.param.woWorkPlanStepCd == "WWPSC99998" &&
                  !_vm.disabled &&
                  !_vm.apprDisabled
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "delete_forever" },
                        on: { btnClicked: _vm.removePlan },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled && !_vm.apprDisabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.data,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "popup-bottom-bar-close" },
            [
              _c("q-btn", {
                attrs: { flat: "", color: "gray", icon: "arrow_back" },
                on: { click: _vm.closePopUps },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }